import {useRouter} from 'next/router';
import React, {useEffect} from 'react';
import {CircularProgress} from '@mui/material';

import {getTokenPayloadFromKeycloak} from '@santa-web/gen/ssp/backoffice-auth';
import {vercelEnv} from '#app/config';

export default function Home() {
  const router = useRouter();

  const getRedirectUrl = async () => {
    try {
      await getTokenPayloadFromKeycloak();
      return '/users';
    } catch (e) {
      if (vercelEnv !== 'production') {
        return '/users';
      } else {
        return '/403';
      }
    }
  };

  useEffect(() => {
    getRedirectUrl().then(url => router.replace(url));
  }, [router]);

  return (
    <>
      <CircularProgress /> Check Authentication...
    </>
  );
}
